import ArticleSection from '@rsa-digital/evo-shared-components/components/ArticleSection';
import { ArticleBlock } from '@rsa-digital/evo-shared-components/components/ArticleSection/types';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import {
  InPageNavDesktop,
  InPageNavMobile,
} from '@rsa-digital/evo-shared-components/components/InPageNav';
import { graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { VideoObject } from 'schema-dts';
import { TableProps } from 'components/blocks/Table';
import { TestimonialProps } from 'components/blocks/Testimonial';
import {
  processMandatoryImage,
  processMandatoryVideo,
  processTable,
} from 'helpers/csTypeProcessors';
import { nonFatalBuildError, warningWithDetail } from 'helpers/errorReporting';
import { CsAsset, CsVideo } from 'types/contentStack';

export type ArticleWithInPageNavProps = {
  in_page_navigation_title: string;
  sections: ArticleWithInPageNavSectionProps[];
};

export type ArticleWithInPageNavSectionProps = {
  article_section: {
    article_blocks: CsArticleBlock[];
  };
  nav_section: {
    inline_nav_text: string;
  };
};

type ArticleBlockWithVideoJsonLd = ArticleBlock & { jsonLd?: string };

type CsArticleBlock = {
  article_text: {
    text: string;
  } | null;
  image_full_width: {
    image: CsAsset;
    caption: string | null;
  } | null;
  image_half_width: {
    image: CsAsset;
    image_alignment: 'left' | 'right';
    text?: string | null;
    caption: string | null;
  } | null;
  video: {
    video: [CsVideo];
  } | null;
  testimonial: TestimonialProps | null;
  table: TableProps | null;
};

export const query = graphql`
  fragment ArticleWithInPageNav on cs__article_with_in_page_navigation {
    in_page_navigation_title
    sections {
      article_section {
        article_blocks {
          article_text {
            text
          }
          image_full_width {
            image {
              ...Asset
            }
            caption
          }
          image_half_width {
            image {
              ...Asset
            }
            image_alignment
            text
            caption
          }
          video {
            video {
              ...Video
            }
          }
          testimonial {
            header_text
            testimonials {
              quote
              screen_reader_text
              author {
                author_job_title
                author_name
              }
            }
          }
          table {
            header_row {
              has_empty_corner_cell
              corner_cell_column_span
              header_cells {
                heading_text
                column_span
              }
            }
            content_rows {
              content_row {
                cells {
                  cell {
                    content_rich_text
                    column_span
                    is_highlighted
                  }
                }
              }
            }
          }
        }
      }
      nav_section {
        inline_nav_text
      }
    }
  }
`;

const processArticleBlock = (block: CsArticleBlock): ArticleBlockWithVideoJsonLd => {
  const blocksByType = Object.entries(block).filter(([, blockContent]) => !!blockContent);
  const csArticleBlockName =
    blocksByType.length && blocksByType[0] ? blocksByType[0][0] : null;
  switch (csArticleBlockName) {
    case 'article_text':
      return {
        type: 'text',
        text: block.article_text?.text ?? '',
      };
    case 'image_full_width':
      return {
        type: 'image-full',
        image: processMandatoryImage(block.image_full_width?.image),
        caption: block.image_full_width?.caption ?? undefined,
      };
    case 'image_half_width':
      if (!block.image_half_width?.text) {
        nonFatalBuildError(
          'Image half-width article block missing text',
          `Image half-width article block for image with filename "${block.image_half_width?.image.filename}" is missing text`
        );
      }
      return {
        type: 'image-half',
        image: processMandatoryImage(block.image_half_width?.image),
        alignment: block.image_half_width?.image_alignment ?? 'left',
        text: block.image_half_width?.text ?? undefined,
        caption: block.image_half_width?.caption ?? undefined,
      };
    case 'video':
      return {
        type: 'video',
        ...processMandatoryVideo(block.video?.video || null),
      };
    case 'testimonial':
      return {
        type: 'testimonial',
        testimonial: {
          headerText: block.testimonial?.header_text ?? undefined,
          showAuthor: true,
          testimonials:
            block.testimonial?.testimonials.map((aTestimonial) => ({
              quote: aTestimonial.quote,
              screenReaderText: aTestimonial.screen_reader_text,
              author: {
                authorName: aTestimonial.author?.author_name ?? undefined,
                authorJobTitle: aTestimonial.author?.author_job_title ?? undefined,
              },
            })) ?? [],
        },
      };
    case 'table':
      if (!block.table) {
        throw new Error('Table is missing');
      }
      return {
        type: 'table',
        table: processTable(block.table),
      };
    default:
      warningWithDetail(
        'Unrecognised article block.',
        'Have you added a new article block type?'
      );
      // In this case, we return an empty text block
      return {
        type: 'text',
        text: '',
      };
  }
};

const ArticleWithInPageNav: React.FC<ArticleWithInPageNavProps> = ({
  in_page_navigation_title,
  sections,
}) => {
  const navSections = sections
    .filter((section) => section.nav_section != null)
    .map((section) => ({
      text: section.nav_section.inline_nav_text,
      id: kebabCase(section.nav_section.inline_nav_text),
    }));

  const articleSections = sections
    .filter((section) => section.article_section != null)
    .map((section, index) => {
      const blocks: ArticleBlockWithVideoJsonLd[] = section.article_section.article_blocks.map(
        (block) => processArticleBlock(block)
      );

      const sectionIndex = sections.indexOf(section);
      const previousSection = sections[sectionIndex - 1];
      const previousNavSectionText = previousSection?.nav_section?.inline_nav_text;

      const videoJsonLdElements = blocks
        .filter((block) => !!block.jsonLd && block.type === 'video')
        .map((block, subIndex) => (
          <Helmet
            // the order of these elements shouldn't change
            // eslint-disable-next-line react/no-array-index-key
            key={subIndex}
            script={[helmetJsonLdProp<VideoObject>(JSON.parse(block.jsonLd as string))]}
          />
        ));

      return (
        // Sections will never be reordered
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {videoJsonLdElements}
          <ArticleSection
            data-cy={`ArticleSection${index}`}
            id={previousNavSectionText ? kebabCase(previousNavSectionText) : undefined}
            blocks={blocks}
          />
        </React.Fragment>
      );
    });
  return (
    <>
      <Grid alignLeft>
        <GridItem desktop={1} tabletLandscape={1} />
        <GridItem desktop={8} tabletLandscape={8}>
          {navSections.length > 0 && (
            <InPageNavMobile
              navHeading={in_page_navigation_title}
              navSections={navSections}
              data-cy="Article mobile in-page nav"
            />
          )}
          <article>{articleSections}</article>
        </GridItem>
        {navSections.length > 0 && (
          <GridItem desktop={3} tabletLandscape={3}>
            <InPageNavDesktop
              navHeading={in_page_navigation_title}
              navSections={navSections}
              data-cy="Article desktop in-page nav"
            />
          </GridItem>
        )}
      </Grid>
    </>
  );
};

export default React.memo(ArticleWithInPageNav);
